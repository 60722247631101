.team-container {
  display: flex;
  flex-direction: column;
}
.member-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 60px;
  padding: 30px;
  /* border: 1px solid black; */
  align-items: center;
  margin: 10px 40px;
}
.member-info {
  text-align: left;
  margin-right: 40px;
  padding: 20px;
  /* border: 1px solid black; */
}

#member-description {
  font-weight: 300;
  text-align: justify;
  line-height: 1.8;
}
.member-photo {
  width: 400px;
  height: 400px;
  object-fit: cover;
  /*  border: 1px solid black; */
  margin-left: 20px;
}
.contact-link {
  color: #66cdcd;

  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  text-decoration: none;

  margin-top: 30px;
  transition: transform 0.3s ease, color 0.3s ease;
}
.contact-link:hover {
  color: #4b4b4b;
}
