
h1,
h2,
h3,
h4,
p {
  color: #616161;
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
}

.main-btn {
  background-color: #616161;
  text-decoration: none;
  color: white;
  border: 1px solid #616161;
  border-radius: 35px;
  padding: 20px 30px;
  font-size: 16px;
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
.main-btn:hover {
  background-color: white;
  color: #616161;
}

/* ➡️➡️➡️HEADER */
.header {
  display: flex;
  flex-direction: row;
  text-align: left;
  margin-bottom: 70px;

  padding: 20px 50px;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    180deg,
    rgba(188, 233, 233, 1) 0%,
    rgba(255, 255, 255, 1) 86%
  );
}
.header-image img {
  width: 25%;
  margin-right: 4vw;
}

.header-text {
  margin-left: 20%;
}

.header-text h2{
  font-weight: 500;
  font-size: 45px;
  
}

.header-text p{
  font-weight: 300;
  margin-bottom: 40px;
}

/* Styles for Panel */

.userpanel2 {
  width: 90%;
  margin: 10px 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 60px;
  margin-bottom: 100px;
}
.userpanel2-group {
  display: flex;
  flex-direction: row;
  text-align: left;
  margin-right: 4px; /* Adjust margin using vw units */
  padding: 3vw;
  align-items: center;
  overflow: hidden;
}
.userpanel2-group p{
  width: 100%;
 font-size: 14px;
 font-weight: 600;
 line-height: 1.5em;
    height: 3em;       /* height is 2x line-height, so two lines will display */
}

.userpanel2-group img {
  width: 150px;
}

/* Styles for Panel 3 */

.userpanel3{
  align-items: center;
  justify-content: space-between;
}

.userpanel3 h3{

  margin-top: 60px;
  margin-bottom: 90px;
  font-weight: 400;
  font-size: 40px;
}

.video{
  display: inline-block;
  vertical-align: top;
  justify-content: left;
  width: 48%;
  margin: 1%;
  
}

.video-text{
  display: inline-block;
  vertical-align: top;
  width: 35%;
  margin: 1%;
  padding-bottom: px;
}
.paragraph{
  font-weight: 500;
  font-size: 23px;
  text-align: left;
  margin: 0;
}
.paragraph1{
  margin-bottom: 50px;
  font-weight: 300;
  text-align: left;
  line-height: 2;
}

.paragraph2{
font-weight: 600;
text-align: left;
line-height: 2;
}

/* Styles for Panel 4 */

.userpanel4{
margin-top: 100px;
justify-content: center;
}

.userpanel4 h3{
  font-weight: 400;
  font-size: 40px;
}

.first{
  justify-content: centre;
  margin: 0;
  width: 100%;

}

.icon1-container{
width: 35%;
display: inline-block;
vertical-align: top;
margin-right: 20px;
justify-content: center;
}

.icon1-text{
display: inline-block;
width: 35%;
vertical-align: top;
font-weight: 400;
text-align: left;
margin-top: 40px;
}

.mini-header{
  font-weight: 700;
  font-size: 23px;
}

.icon1{
  width: 45%;
  margin-left: 20px;
}

.icon{
  width: 60%;
  margin-left: 20px;
}

/* Testimonial styling */

.custom-shape-divider-top-1718802291-2 {
  position: absolute;
  top: -1px; /* Adjust to place it just above testimonials-container */
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  z-index: 3; /* Ensure it's above testimonials container */
}

.custom-shape-divider-top-1718802291-2 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 70px; /* Adjust height as needed */
}

.custom-shape-divider-top-1718802291-2 .shape-fill {
  fill: white; /* Set the divider color to red */
}

.testimonials-container {
  background-color: #f7f7f7;
  padding: 0px;
  margin: 0px;
  position: relative; /* Ensure testimonials-container has relative positioning */
  z-index: 2; /* Set z-index lower than the red divider to ensure it appears below */
}

.testimonials {
  margin-top: 0;
}

.testimonials img {
  border-radius: 50%;
  width: 180px;
  height: 180px;
  margin: 0 auto;
  display: block;
  object-fit: cover;
}

/*.testimonials-container p {
  margin-top: 30px;
  font-weight: 500;
  font-size: 30px;
}*/

.testi-paragraph{
  margin-top: 30px;
  font-weight: 500;
  font-size: 45px;
  
}

.testimonials-text{
  padding: 8px 28px;
  margin-right: 25px;
  font-size: 18px;
  font-weight: 600;
}

.testimonials-names{
  margin: 0;
  padding: 0;
}

/*.custom-shape-divider-top-1718802291-2 {
  position: absolute;
  top: -1px; /* Adjust to place it just above testimonials-container 
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  z-index: 3; /* Ensure it's above testimonials container 
}
*/

.custom-shape-divider-top-1718802291-2 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 103px; /* Adjust height as needed */
}

.custom-shape-divider-top-1718802291-2 .shape-fill {
  fill: white; /* Set the divider color to red */
}

.testimonials-container {
  background-color: #f7f7f7;
  padding: 80px 0px;
  position: relative; /* Ensure testimonials-container has relative positioning */
  z-index: 2; /* Set z-index lower than the red divider to ensure it appears below */
}

.testimonial {
  margin-top: 30px;
}

.testimonial img {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  display: block;
  object-fit: cover;
}

.testimonial h3 {
  padding: 20px 60px;
  margin-right: 30px;
  font-size: 1.4vw;
}
.testimonials-container h1 {
  margin-top: 160px;
}

/* ➡️➡️➡️PANEL6 */
.custom-shape-divider-top-1718802291-3 {
  position: absolute;
  top: -1px; /* Adjust to place it just above panel7 */
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  z-index: 3; /* Ensure it's above panel7 */
}

.custom-shape-divider-top-1718802291-3 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 103px; /* Adjust height as needed */
}

.custom-shape-divider-top-1718803736-3 .shape-fill {
  fill: #f7f7f7; /* Set the divider color to red */
}
.userpanel6 {
  width: 100%;
  background-color: #f7f7f7;
  padding: 100px 0px;
}
.userpanel6 h3{
  margin-bottom: 50px;
  font-size: 32px;
  font-weight: 400;
}

/* ➡️➡️➡️PANEL7 */
.userpanel7{
 width: 100%;
 align-items: center;
 justify-content: center;
 margin-top: 50px;
}

.userpanel7 h2{
  font-weight: 500;
  font-size: 37px;
}

.userpanel7 p{
  font-weight: 300;
  justify-content: center;
}

.userpanel8{
  display: flex;
  gap: 40px;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 150px;


}
.userpanel8-box{
padding: 20px;
border-radius: 20px;
border: 2px solid #cbc9c9;
width: 500px;
height: auto;
overflow: hidden;
box-sizing: border-box;
margin-left: 0px 10px; 

}

.userpanel8-box img{
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

.userpanel8-box h3{
 font-size: 35px;
 font-weight: 500;
 text-align: left;
 margin-bottom: 20px;
}

.userpanel8-box p{
  text-align: left;
  font-weight: 300;
  margin-top: 10px;
}

.userpanel9{
  position: relative;
  width: 100%;

}
.container9 {
  position: absolute;
  top: 20%;
  margin-left: 30%;
}

.container9 h3{
  font-weight: 500;
  font-size: 35px;
  margin-bottom: 70px;
}
