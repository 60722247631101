.contact-page-container {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  /*   border: 1px solid black; */
  /* align-items: center; */
  justify-content: center;
  padding: 30px;
  font-size: 18px;
}
.contact-left-panel {
  /* border: 1px solid black; */
  text-align: left;
  margin-right: 50px;
}
.contact-left-panel p {
  line-height: 1.8;
}
.fa-brands {
  margin-top: 20px;
  color: #616161;
  margin-left: 10px;
  font-size: 20px;
}
.contact-right-panel {
  margin-top: 20px;
  /* border: 1px solid black; */
  width: 100%;
}
.contact-name {
  display: flex;
  flex-direction: row;
}
label {
  color: #616161;
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
}
form input, textarea {
  width: 90%;
  height: 30px;
  margin-bottom: 20px;
  margin-top: 7px;
  background-color: #ffffff;
  border: 1px solid #616161;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  color: #616161;
  box-sizing: border-box; /*Makes the padding included in the border*/
  width: 300px;
  text-align: left;
white-space: pre-wrap;
  overflow-x: scroll;
  overflow-wrap: break-word;
}
.contact-name input {
  width: 80%;
  
}
#contact-message {
  height: 100px;
}
.send-btn {
  background-color: black;
  padding: 15px 25px;
  color: white;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  border-radius: 40px;
  border: 1px solid black;
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
}
.send-btn:hover {
  background-color: white;
  color: black;
  border: 1px solid black;
}
