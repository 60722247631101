/* ➡️➡️➡️GENERAL */
h1,
h2,
h3,
h4,
p {
  color: #616161;
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
}

.main-btn {
  background-color: #616161;
  text-decoration: none;
  color: white;
  border: 1px solid #616161;
  border-radius: 35px;
  padding: 20px 30px;
  font-size: 16px;
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
.main-btn:hover {
  background-color: white;
  color: #616161;
}

/* ➡️➡️➡️HEADER */
.header {
  display: flex;
  flex-direction: row;
  text-align: left;

  padding: 40px 100px;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    180deg,
    rgba(188, 233, 233, 1) 0%,
    rgba(255, 255, 255, 1) 86%
  );
}
.header-image img {
  width: 35vw;
  margin-right: 4vw;
}
#companies-tagline {
  margin-top: 25px;
  margin-bottom: 60px;
  font-weight: 200;
  margin-right: 60px;
  font-size: 1.3vw;
}
.header-text {
  margin-left: 4vw;
}

/* ➡️➡️➡️PANEL2 */
.panel2 {
  width: 100%;
  margin: 30px 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.panel2-group {
  display: flex;
  flex-direction: row;
  text-align: left;
  margin-right: 2vw; /* Adjust margin using vw units */
  padding: 3vw;
  align-items: center;
}
.panel2-group p {
  font-size: 1.1vw;
}
.panel2-group img {
  width: 90px;
}

/* ➡️➡️➡️PANEL3 */
.panel3 {
  margin: 80px 100px;
  width: 100%;
}

/* ➡️➡️➡️PANEL4 */
.custom-shape-divider-top-1718802291 {
  position: absolute;
  top: -1px; /* Adjust to place it just above panel4 */
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  z-index: 1; /* Ensure it's above other content in panel4 */
}

.custom-shape-divider-top-1718802291 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 80px;
}

.custom-shape-divider-top-1718802291 .shape-fill {
  fill: white;
}

.panel4 {
  position: relative;

  margin-top: -30px; /* Adjust as needed to position panel4 correctly */
  background-color: #f7f7f7;
  z-index: 2; /* Ensure it's above the divider */
  width: 100%; /* To take up all the space of the parent(companies homepage)*/
  box-sizing: border-box; /* Include padding in width calculation */
  overflow: hidden; /* Ensure no content overflows outside */
  width: 100%;
}
.panel4 h1 {
  margin-top: 180px;
  margin-bottom: 30px;
}
.panel4-groups {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 70px;
  padding: 20px;
}
.panel4-group {
  margin-right: 20px;
  padding: 30px 30px;
}
.panel4-group img {
  width: 90px;
}
.panel4 h2 {
  font-size: 1.3vw;
}
.panel4-group p {
  font-weight: 200;

  font-size: 1.2vw;
}

/* ➡️➡️➡️PANEL5 */

.panel5 {
  padding: 80px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.panel5-groups {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the rows within the parent container */
  /* margin-left: 400px; */
}

.panel5-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start; /* Align items to the start of the row */
  align-items: center; /* Vertically align items in the center */
  padding: 30px;
  text-align: left;
  width: 100%; /* Ensure each row takes the full width of the container */
  max-width: 1200px; /* Optionally set a max width for better layout control */
}

.panel5-row img {
  width: 300px; /* Set a consistent width */
  margin-right: 20px; /* Add margin to create space between image and text */
}
.panel5 h2 {
  font-size: 1.3vw;
}
.panel5 p {
  font-size: 1.2vw;
}
/* ➡️➡️➡️PANEL6 - COMPANIESTESTIMONIALS.JSX */
/* Ensure the red divider is above the testimonials container */
.custom-shape-divider-top-1718802291-2 {
  position: absolute;
  top: -1px; /* Adjust to place it just above testimonials-container */
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  z-index: 3; /* Ensure it's above testimonials container */
}

.custom-shape-divider-top-1718802291-2 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 103px; /* Adjust height as needed */
}

.custom-shape-divider-top-1718802291-2 .shape-fill {
  fill: white; /* Set the divider color to red */
}

.testimonials-container {
  background-color: #f7f7f7;
  padding: 80px 0px;
  position: relative; /* Ensure testimonials-container has relative positioning */
  z-index: 2; /* Set z-index lower than the red divider to ensure it appears below */
}

.testimonial {
  margin-top: 30px;
}

.testimonial img {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  display: block;
  object-fit: cover;
}

.testimonial h3 {
  padding: 20px 60px;
  margin-right: 30px;
  font-size: 1.4vw;
}
.testimonials-container h1 {
  margin-top: 160px;
}

/* ➡️➡️➡️PANEL7 */
.custom-shape-divider-top-1718802291-3 {
  position: absolute;
  top: -1px; /* Adjust to place it just above panel7 */
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  z-index: 3; /* Ensure it's above panel7 */
}

.custom-shape-divider-top-1718802291-3 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 103px; /* Adjust height as needed */
}

.custom-shape-divider-top-1718803736-3 .shape-fill {
  fill: #f7f7f7; /* Set the divider color to red */
}
.panel7 {
  width: 100%;
  background-color: #f7f7f7;
  padding: 100px 0px;
}
.panel7 h1 {
  margin-bottom: 100px;
}
