.credits-container {
  padding: 80px 0px;
  width: 100%;
}
.credits-first-row {
  display: flex;
  justify-content: center;
}
.credits-first-row img {
  margin-right: 20px;
  width: 90px;
  border-radius: 10px;
  margin-bottom: 50px;
}
.credits-third-row {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.credits-third-row img {
  width: 150px;
  max-width: 100%;
  margin-right: 30px;
}
#europe-logo{
  width: 230px;
}