.impressum-container {
  display: flex;
  flex-direction: row;

  border-radius: 15px;
  padding: 40px 70px;

  text-align: left;
  font-weight: 300;
  margin-top: 40px;

  align-items: center;
  justify-content: center;
  font-family: "Poppins", sans-serif;
}
.impressum-column1,
.impressum-column2 {
  display: flex;
  flex-direction: column;
  margin-right: 60px;
  /* Vertical alignment within each column */
}
.impressum-container p {
  font-size: 18px;
}
.labels {
  font-weight: 400;
}
