.secondary-btn {
  color: white;
  padding: 10px;

  font-weight: 400;
  background-color: #66cdcd;
  border: none;
  border-radius: 10px;
  margin-top: 30px;
  transition: transform 0.3s ease;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  text-align: center;
}
.secondary-btn:hover {
  transform: scale(1.03);
}
