.main-btn {
  background-color: #616161;
  text-decoration: none;
  color: white;
  border: 1px solid #616161;
  border-radius: 35px;
  padding: 20px 30px;
  font-size: 16px;
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
.main-btn:hover {
  background-color: white;
  color: #616161;
}