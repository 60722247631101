.footer-container {
  padding: 30px;
  /* border: 1px solid black; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: #38383b;
  margin-top: 100px;
  width: 100%;
}

.footer-container h1,
.footer-container h3,
.footer-container p,
.footer-container a {
  color: white;
  font-family: "Poppins", sans-serif;
}

.footer-left-panel {
  display: flex;
  flex-direction: column;
  /*   border: 1px solid black;
 */
  align-items: left;

  text-align: left;
}
.footer-socials {
  display: flex; /* Ensure social icons are displayed in a row */
  margin-right: 20px; /* Adjust spacing between social icons */
  margin-bottom: 30px;
}

.footer-socials a {
  margin-right: 10px; /* Adjust spacing between individual social icons */
  color: white;
}
#footer-social-icon {
  color: white;
}
.footer-contact-btn {
  background-color: #616161;
  text-decoration: none;
  color: white;
  border: 1px solid #616161;
  border-radius: 35px;
  padding: 20px 30px;
  font-size: 16px;
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  width: 100px;
}
.footer-contact-btn:hover {
  background-color: white;
  color: #616161;
}
.footer-right-panel {
  /*   border: 1px solid black;
 */
  display: flex;
  flex-direction: column;
  text-align: right;
}
.footer-link {
  line-height: 2;
  margin-bottom: 15px;
}
