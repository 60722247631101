.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  background-color: white;
  font-family: "Poppins", sans-serif;
  height: 2vw;
}

.navbar-logo img {
  width: 150px;
}

.navbar-links a {
  color: #616161;
  text-decoration: none;
  margin-left: 30px;
  font-size: 1vw;
}

.navbar-links a:hover {
  color: #ddd;
}
